import React, { useState } from "react";
import "./LoginForm.css";
import { FaUser, FaLock } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const LoginForm = (props) => {
    const [email, setEmail] = useState("")
    const [username, setUsername] = useState("")
    const [name, setName] = useState("")
    const [password, setPassword] = useState("")
    const [username_register, setUsernameRegister] = useState("")
    const [password_register, setPasswordRegister] = useState("")
    const [repeat_password, setRepeatPassword] = useState("")
    const [login, setLogin] = useState(true)
    const [emailError, setEmailError] = useState("")
    const [nameError, setNameError] = useState("")
    const [repeat_passwordError, setRepeatPasswordError] = useState("")
    const [usernameError, setUsernameError] = useState("")
    const [passwordError, setPasswordError] = useState("")
    const [usernameRegisterError, setUsernameRegisterError] = useState("")
    const [passwordRegisterError, setPasswordRegisterError] = useState("")
    
    const navigate = useNavigate();
    
    const onButtonClick = () => {

        // Set initial error values to empty
        setUsernameError("")
        setPasswordError("")

        // Check if the user has entered both fields correctly
        if ("" === username) {
            setUsernameError("Please enter your username")
            return
        }

        // if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
        //     setEmailError("Please enter a valid email")
        //     return
        // }

        if ("" === password) {
            setPasswordError("Please enter a password")
            return
        }

        if (password.length < 7) {
            setPasswordError("The password must be 8 characters or longer")
            return
        }
        
        logIn()
            
    }

    const onButtonSaveRegisterClick = () => {
        setEmailError("")
        setNameError("")
        setUsernameRegisterError("")
        setRepeatPassword("")
        setPasswordRegisterError("")

        // Check if the user has entered both fields correctly
        if ("" === email) {
            setEmailError("Please enter your email")
            return
        }

        if ("" === name) {
            setNameError("Please enter your name")
            return
        }

        if ("" === username) {
            setUsernameRegisterError("Please enter your username")
            return
        }

        if ("" === repeat_password) {
            setRepeatPassword("Please enter your repeat password")
            return
        }

        if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
            setEmailError("Please enter a valid email")
            return
        }

        if ("" === password) {
            setPasswordRegisterError("Please enter a password")
            return
        }

        if (password.length < 7) {
            setPasswordRegisterError("The password must be 8 characters or longer")
            return
        }
        
        fetch("http://localhost:8080/users", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
              },
            body: JSON.stringify({email,name,username_register,repeat_password,password_register})
        })
        .then(r => r.json())
        .then(r => {
            if (r.success) {
                setLogin(false);
                navigate("/login")
            } else {
                window.alert("Something Wrong")
            }
        })
    }

    // Log in a user using email and password
    const logIn = () => {
        fetch("http://localhost:8080/users/login", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
              },
            body: JSON.stringify({username, password})
        })
        .then(r => r.json())
        .then(r => {
            if (r.success) {
                localStorage.setItem("user", JSON.stringify({username, token: r.data.token}))
                props.setLoggedIn(true)
                props.setUsername(username)
                navigate("/")
            } else {
                window.alert("Wrong email or password")
            }
        })
    }

    return (
        <div>
            <div className={login? 'wrapper' : 'hidden'}>
                <h1>Login</h1>
                <img className="logo-login" src="/img/logo_qwera.png" />
                <div className="input-box">
                    <input type="text" value={username} onChange={ev => setUsername(ev.target.value)} placeholder="Username" required />
                    <FaUser className="icon"/>
                    <label className="errorLabel">{usernameError}</label>
                </div>
                <div className="input-box">
                    <input type="password" placeholder="Password" value={password} onChange={ev => setPassword(ev.target.value)} required />
                    <FaLock className="icon"/>
                    <label className="errorLabel">{passwordError}</label>
                </div>
                <div className="remember-forgot">
                    <label><input type="checkbox"/>Remember me</label>
                    <a href="#">Forgot Password</a>
                </div>

                <button type="button" onClick={onButtonClick}>Login</button>

                <div className="register-link">
                    <p>Tidak Mempunyai akun? <a href="#" onClick={ev => setLogin(false)}>Register</a></p>
                </div>
            </div>
            <div className={login? 'hidden' : 'wrapper'}>
                <h1>Register</h1>
                <img className="logo-login" src="/img/logo_qwera.png" />
                <div className="input-box">
                    <input type="text" value={username_register} onChange={ev => setUsernameRegister(ev.target.value)} placeholder="Username" required />
                    <FaUser className="icon"/>
                    <label className="errorLabel">{usernameRegisterError}</label>
                </div>
                <div className="input-box">
                    <input type="text" value={email} onChange={ev => setEmail(ev.target.value)} placeholder="Email" required />
                    <FaUser className="icon"/>
                    <label className="errorLabel">{emailError}</label>
                </div>
                <div className="input-box">
                    <input type="text" value={name} onChange={ev => setName(ev.target.value)} placeholder="Name" required />
                    <FaUser className="icon"/>
                    <label className="errorLabel">{nameError}</label>
                </div>
                <div className="input-box">
                    <input type="password" placeholder="Password" value={password_register} onChange={ev => setPasswordRegister(ev.target.value)} required />
                    <FaLock className="icon"/>
                    <label className="errorLabel">{passwordRegisterError}</label>
                </div>
                <div className="input-box">
                    <input type="password" placeholder="Ulangi Password" value={repeat_password} onChange={ev => setRepeatPassword(ev.target.value)} required />
                    <FaLock className="icon"/>
                    <label className="errorLabel">{repeat_passwordError}</label>
                </div>
                <div className="button-container">
                    <button type="button" className="m-5" onClick={ev => setLogin(true)}>Back To Login</button>
                    <button type="button" className="m-5" onClick={onButtonSaveRegisterClick}>Register</button>
                </div>
            </div>
        </div>
    );
};

export default LoginForm